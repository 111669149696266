import React from "react"

import Container from "layout/Container"
import HomeMediaContainer from "./HomeMediaContainer"

import programEnhancement from "../utils/programEnhancement.json"
import useHomeImages from "../hooks/useHomeImages"

import {
  GATSBY_PULSECARE_EMAIL,
  GATSBY_PULSECARE_CONTACT_NUMBER,
} from "gatsby-env-variables"

const contactStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  margin: "0 auto",
}

const HomeFeatures = () => {
  const data = useHomeImages()
  return (
    <Container customClassName="p-3-mobile" isCentered desktop={10} fullhd={8}>
      <div id="program-enhancement">
        <h3 className="title my-3 has-text-centered has-text-primary">
          Starting July 15, the Pulse Care Program will now offer:
        </h3>
        <div className="columns is-centered mb-3 mt-3">
          {programEnhancement.map((item) => (
            <HomeMediaContainer
              title={item.title}
              description={item.description}
              image={data[item.imageName].childImageSharp.fixed}
            />
          ))}
        </div>
        <p className="mb-1 mx-3-mobile is-size-6 has-text-centered">
          To know more, contact us at{" "}
          <a
            className="has-text-weight-bold"
            href={`mailto:${GATSBY_PULSECARE_EMAIL}`}
            style={contactStyle}
          >
            {GATSBY_PULSECARE_EMAIL}
          </a>{" "}
          or 09178482088
        </p>
      </div>
    </Container>
  )
}

export default HomeFeatures
